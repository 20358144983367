import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title } from "../components/Core"

import renderSlices from "../components/Slices"
import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const News = ({ data }) => {
  const news = data.prismicNews

  const { title, excerpt, cover_image, body: slices = [] } = news.data

  const { href } = useLocation()

  const post_url = href

  return (
    <>
      <GatsbySeo
        title={news.data.meta_title?.text || news.data.title?.text}
        description={news.data.meta_description?.text}
      />
      <PageWrapper>
        <Hero bgImg={cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {title && (
                  <Title variant="hero" color="light">
                    {title.text}
                  </Title>
                )}
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <div className="social-share mb-3">
                  <FacebookShareButton
                    url={post_url}
                    quote={title.text || "emeigroup"}
                    hashtag="#emeigroup"
                  >
                    <FacebookIcon size={36} />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={post_url}
                    quote={title.text || "emeigroup"}
                    hashtag="#emeigroup"
                    className="mx-2"
                  >
                    <TwitterIcon size={36} />
                  </TwitterShareButton>
                </div>
                <div>
                  {slices.length > 0 &&
                    slices.map(({ slice_type, primary, items }, i) =>
                      renderSlices(slice_type, primary, items, `key_${i}`)
                    )}
                </div>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default News

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicNews(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      data {
        title {
          text
        }
        cover_image {
          url
        }
        excerpt {
          text
        }
        cover_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }

        body {
          ... on PrismicNewsBodySection {
            slice_type
            items {
              div {
                raw
              }
            }
            primary {
              title1 {
                text
              }
            }
          }
          ... on PrismicNewsBodyImageGallery {
            slice_type
            primary {
              title1 {
                text
              }
            }
            items {
              caption {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`
